.widget-contact {
    .slanted-background {
        fill: $green;
    }

    .contact {
        padding: 3rem 0;

        h2, p, a {
            color: $white;
        }

        .layout-icon-links {
            margin-top: 3.4375rem;

            a {
                .fa {
                    color: $blue;
                }
            }
        }
    }

    .rte {
        margin-top: 1rem;
    }

    .grid-container {
        position: relative;
    }

    .umbraco-forms-form {
        background: $white;
        padding: 3rem;
        margin-top: 3rem;
    }

    @include breakpoint(medium only) {
        .umbraco-forms-form {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @include breakpoint(large) {
        .contact {
            position: relative;
            padding: 9.0625rem 0 0;
        }

        .grid-container {
            position: relative;
            z-index: 1;

            > .grid-x {
                > .cell {
                    &:first-child {
                        padding-right: 2rem;
                    }

                    &:nth-child(2) {
                        padding-left: 2rem;
                    }
                }
            }
        }

        .umbraco-forms-form {
            padding: 5rem 2rem;
            margin-top: 0;

            .industry {
                .umbraco-forms-field-wrapper {
                    display: flex;
                    flex-flow: row wrap;

                    .form-check {
                        display: flex;
                        width: 50%;
                    }

                    .form-check-label {
                        width: 75%;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 100rem) {
        .umbraco-forms-form {
            padding: 5rem 5.3125rem;
            margin-top: 0;
        }
    }
}
