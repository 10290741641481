﻿@mixin colorButton($buttonColor, $textColor, $iconColor, $iconHoverColor: $white, $bgHoverColor: $buttonColor, $flagImportant: false, $fillColor: transparent) {
    border-color: $buttonColor;

    @if($flagImportant) {
        color: $textColor !important;
    }
    @else {
        color: $textColor;
    }

    &::after {
        border-color: $buttonColor;
        color: $iconColor;
    }

    &:hover {
        &::after {
            color: $iconHoverColor;
            background: $buttonColor;
        }
    }
}