.widget-groupings {
    .slanted-background {
        fill: $green;
    }

    img.icon {
        width: 80px;
        height: 80px;
    }

    .description {
        padding: 3rem 0 0;
        position: relative;

        img {
            margin-bottom: 1.5625rem;
        }
    }

    .images-container {
        margin-bottom: 3.25rem;
        // margin-top: 3rem;
        svg {
            display: none;
        }

        .circle-container {
            z-index: 3;
        }

        .circle {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            margin-bottom: 3rem;
        }

        h5 {
            text-align: center;
            color: $blue;
            width: 100%;
            padding-bottom: 3px;
            margin-top: 2.375rem;
            position: relative;

            &::after {
                bottom: 2px;
            }
        }
    }

    .button-placement {
        margin-bottom: 7rem;
    }

    @include breakpoint(large) {

        img.icon {
            width: 98px;
            height: 98px;
        }

        .groupings {
            margin-bottom: 7rem;

            .description {
                padding: 7.5rem 0 0;

                img {
                    width: 6.125rem;
                    margin-bottom: 3.125rem;
                }
            }

            .images-container {
                position: relative;
                margin-top: -5rem;
                margin-bottom: 0;
                z-index: 1;

                .grid-x {
                    margin: 0 -3rem;
                }

                .cell {
                    width: calc(33.33333% - 6rem);
                    margin: 0 3rem;

                    &:first-child {
                        padding-top: 11%;
                    }

                    &:nth-child(2) {
                        padding-top: 5.5%;
                    }
                }

                .circle {
                    z-index: 2;
                    margin-bottom: 0;
                }
            }

            .button-placement {
                background: $white;
                padding-top: 6.25rem;
                margin-bottom: 0;
            }
        }
    }
}
