﻿.reveal {
    padding: 3rem 0 0;
    background-color: transparent;
    border: none;

    &:focus {
        outline: none;
    }

    &.speech-bubble {
        overflow: hidden;
        height: auto;
        min-height: 0;

        .close-button {
            width: 27px;
            height: 27px;
            top: 4.5rem;
            right: 2.1875rem;
            color: $red;
            font-size: 1.75rem;
            line-height: 1.9375rem;
            transition: all 0.3s ease;
            outline: none;
            overflow: hidden;
            text-indent: -9999px;
            text-align: center;

            &:before,
            &:after {
                content: "";
                width: 19px;
                height: 2px;
                position: absolute;
                top: 50%;
                left: 3px;
                margin: -1px 0 0;
                background: $red;
                transition: all 0.3s ease;
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }

            &:hover {
                color: $white;
                text-decoration: none;

                &:before,
                &:after {
                    background: $dark-red;
                }
            }
        }

        .wrapper {
            background: $white;
            margin-left: auto;
            margin-right: auto;
            border-radius: 1.25rem;
            padding: 3rem 1.25rem;
            top: 2.5%;
            // overflow-y: scroll;
        }
    }

    @include breakpoint(large) {
        &.speech-bubble {
            .wrapper {
                border-radius: 3.125rem;
                padding: 5.9375rem 6.5625rem 5rem;
                min-height: 0;
                height: auto;
                overflow-y: hidden;
            }

            &::after {
                content: '';
                display: block;
                width: 0;
                position: relative;
                bottom: 0;
                left: 10%;
                height: 100px;
                border-top: 100px solid $white;
                border-right: 120px solid transparent;
            }

            &.large {
                &::after {
                    height: 250px;
                    border-top: 250px solid $white;
                    border-right: 300px solid transparent;
                }
            }
        }
    }
}

.reveal-overlay {
    background: rgba(10,10,10,0.65);
}