﻿@import '../mixins/_colorButton.scss';
.button {
    border: none;
    background: transparent;
    color: $red !important;
    border: 1px solid $red;
    border-radius: 3.5rem;
    min-width: 10.625rem;
    font-size: 1rem;
    text-align: left;
    display: inline-block;
    padding: 0.8125rem 4.6875rem 0.8125rem 1.5625rem;
    position: relative;
    cursor: pointer;
    font-weight: bold;
    line-height: 1.75rem;

    &::after {
        font-weight: normal;
        font-family: $shift;
        content: '\f009';
        color: $red;
        font-size: 3.125rem;
        line-height: 3.375rem;
        position: absolute;
        top: -1px;
        right: -1px;
        border: 1px solid $red;
        width: 3.562556rem;
        height: 3.5rem;
        border-radius: 3.5rem;
        text-align: center;
        transition: background-color 0.3s ease-in-out, color .3s ease-in-out;
    }

    &:hover {
        &::after {
            color: $white;
            background: $red;
        }
    }

    &.btn-green {
        @include colorButton($green, $green, $green, $white, $green, true);
    }

    &.btn-blue {
        @include colorButton($blue, $blue, $blue, $blue, $blue, true);
    }

    &.btn-white {
        @include colorButton($white, $white, $white, $green, $white, true);
    }

    &[disabled] {
        @include colorButton($grey, $grey, $grey, $white, $grey, true);

        &::after {
            animation: fa-spin 2s infinite linear;
            content: '\f00e';
            font-size: 2rem;
        }
    }
}

.with-right-arrow {
    &[disabled] {
        color: $grey;

        &::after {
            animation: fa-spin 2s infinite linear;
            content: '\f00e';
            font-size: 1em;
            width: 1em;
            transform-origin: center center;
            margin-left: 1.5rem;
        }
    }
}

.play-button {
    color: $red;
    display: inline-flex;
    align-items: center;
    font-family: $museo;
    font-weight: bold;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2rem;
        border: 2px solid $red;
        background-color: transparent;
        transition: background-color .3s, color .3s;
        color: $red;
        width: 4rem;
        height: 4rem;
        font-size: 1.5rem;
        margin-right: 1rem;
        flex: 0 0 auto;

        &::before {
            position: relative;
            left: .125em;
        }
    }
    &:hover {
        color: $red;
        span {
            background-color: $red;
            color: $white;
        }
    }
}

.carousel-buttons {
    $dimensionControl: 3.625rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    width: 100%;

    a {
        display: flex;
        width: $dimensionControl;
        height: $dimensionControl;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
        border-radius: $dimensionControl;
        justify-content: center;
        align-items: center;
        background: $white;
        position: absolute;

        .fa {
            color: $red;
            font-size: 40px;
        }

        $controlDistance: .5rem;

        &.prev {
            left: $controlDistance;
        }

        &.next {
            right: $controlDistance;
        }
    }
}

.pill-button {
    display: flex;
    flex-direction: column;
    height: 4.375rem;
    width: 340px;
    font-family: $museo;
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: 0.46px;
    line-height: 1.375rem;
    color: $grey;
    margin-bottom: 0;
    border-radius: 4.375rem;
    box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.3);
    padding: .9375rem 2rem;
    justify-content: center;
    background-color: $white;

    span {
        display: block;
        color: $red;
        font-size: 0.75rem;
        line-height: 1.125rem;
        letter-spacing: 2px;
        font-weight: 600;
        text-transform: uppercase;
    }
}