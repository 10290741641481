.widget-cta {
    .rte {
        text-align: center;

        img {
            height: 100px;
            margin-bottom: 3.125rem;
        }

        h2 {
            margin-bottom: 3.125rem;
        }
    }

    &.variant-basic {
        padding: 6.375rem 0;

        &.layout-dark {
            background-color: $green;

            .rte {
                h1, h2, h3, h4, h5, h6, p, ol, ul {
                    color: $white;
                }

                .button {
                    @include colorButton($white, $white, $white, $green, $white, true);
                }
            }
        }

        &.layout-light {
            background-color: $white;

            .rte {
                h1, h2, h3, h4, h5, h6, p, ol, ul {
                    color: $green;
                }

                .button {
                    @include colorButton($green, $green, $green, $white, $green, true);
                }
            }
        }
    }

    &.variant-with-image {
        .image-container {
            min-height: 25rem;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            display: flex;
            align-items: flex-end;
            padding: 3.125rem;

            &::before {
                content: '';
                position: absolute;
                background: $blue;
                background: linear-gradient(0deg, rgba(12,39,62,1) 3%, rgba(22,71,112,0) 40%);
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        .rte {
            position: relative;
            z-index: 1;
            text-align: left;
            width: 450px;
            max-width: 100%;

            h3, h4 {
                color: $white;
            }

            a {
                margin-top: 1.5625rem;
            }
        }

        @include breakpoint(large) {
            .image-container {
                padding: 3.125rem 15%;
                min-height: 37.5rem;
            }
        }
    }
}
