.layout-highlights {
    .highlights {
        display: flex;
        flex-flow: column nowrap;
        margin-bottom: -3rem;

        > div {
            margin-bottom: 3rem;

            img {
                width: 3.125rem;
                height: 3.125rem;
                margin-bottom: 1.40625rem;
            }

            .rte {
                margin-bottom: 2.1875rem;
            }
        }
    }

    @include breakpoint(large) {
        .highlights {
            flex-flow: row wrap;
            margin-bottom: -6rem;

            > div {
                width: 50%;
                margin-bottom: 6rem;
                padding-right: 6rem;

                img {
                    width: 6.25rem;
                    height: 6.25rem;
                    margin-bottom: 2.8125rem;
                }
            }
        }
    }
}
