.widget-quote {
    blockquote {
        font-family: $museo;
        font-size: 1.875rem;
        line-height: 2.5rem;
        font-weight: 500;
        position: relative;
        border-left: 6px solid $red;
    }

    &.variant-basic {
        blockquote {
            color: $red;
            margin-left: 2rem;
            padding: .5625rem 0 .5625rem 1.5rem;
        }

        @include breakpoint(medium) {
            blockquote {
                padding: .5625rem 0 .5625rem 3.5625rem;
            }
        }

        @include breakpoint(large) {
            blockquote {
                margin-left: 10.9375rem;
            }
        }
    }

    &.variant-with-image {
        .image-container {
            min-height: 25rem;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 3.125rem;
            background-color: $blue;

            &::before {
                content: '';
                position: absolute;
                background-image: linear-gradient(to left, rgba(12,39,62,1) 0%, rgba(22,71,112,0) 100%);
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        blockquote {
            color: $white;
            border-left-color: $white;
            padding: 3.75rem 0 3.75rem 3.5625rem;
        }

        @include breakpoint(large) {
            .image-container {
                padding: 3.125rem 7%;
                min-height: 37.5rem;

                &::before {
                    background-image: linear-gradient(to left, rgba(12,39,62,1) 0%, rgba(22,71,112,0) 50%);
                }
            }

            blockquote {
                width: 27.5rem;
            }
        }
    }
}
