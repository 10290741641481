﻿.lock-scroll {
    overflow: hidden;
}

.reveal {
    &.standard {
        text-align: right;
        padding-top: 77px;

        .message, .close {
            background-color: $white;
        }

        .message {
            text-align: left;
            border-radius: 30px;
            padding: 3rem 6rem 6rem;

            > h3 {
                padding-bottom: 1.5rem;
                margin-bottom: 2.5rem;
                border-bottom: 2px solid $light-grey;
            }
        }

        .close {
            width: 53px;
            height: 53px;
            border-radius: 50%;
            color: $red;
            position: absolute;
            transition: .3s background-color;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 1rem;
            font-size: 2rem;
            right: 0;
            top: 0;
            padding-top: 0.3rem;

            &:hover {
                background-color: $light-grey;
            }
        }

        .grid-level-1 {
            min-height: 0;
        }
    }
}