@mixin backgroundStyling {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.pillars {
    min-height: calc(100vh - 111px);
    padding: 1rem 0;
    position: relative;
    display: flex;
    align-items: center;

    > .banner-bg {
        @include backgroundStyling();
        z-index: 0;

        > .backgrounds {
            @include backgroundStyling();

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;

                @media(min-width: 640px) {
                    background: linear-gradient(95deg, rgba(12,39,62,1) 40%, rgba(255,255,255,0) 100%);
                }

                @media(max-width: 639px) {
                    background: #0c273e;
                }

                z-index: 4;
            }

            > div {
                @include backgroundStyling();
                background-size: cover;
                background-position: top left;
                opacity: 0;
                z-index: 1;

                &.active {
                    opacity: 1;
                    z-index: 2;
                }
            }
        }
    }

    .content {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        position: relative;
        z-index: 2;
        color: $white;
    }

    .rte {
        h1, h2, h3, h4, h5, h6, p, ol, li {
            color: $white;
        }

        h2 {
            font-size: 1.875rem;
            line-height: 2.5rem;
        }
    }

    .listings {
        list-style: none;
        display: inline-block;
        margin-left: 0;

        li {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            cursor: default;

            .thumbnail {
                display: none;
            }

            h4 {
                border-top: 1px solid $white;
                padding: 1.5625rem 0;
                color: $light-grey;
                transition: all 0.5s ease;
                font-size: 1.5rem;
                line-height: 2.125rem;
            }

            a.btn-arrow {
                display: none;
            }

            &:first-child {
                h4 {
                    border-top: none;
                }
            }
        }
    }
}

@include breakpoint(large) {
    .pillars {
        background-color: $blue;

        > .banner-bg {
            > div {
                background-size: 100% 100%;
                background-position: top right;
            }
        }

        .content {
            width: 100%;
        }

        .rte {
            h2 {
                font-size: 2.25rem;
                line-height: 2.875rem;
            }
        }

        .listings {
            margin: 1rem 0 2rem -12rem;

            li {
                $thumbnailDimension: 8.8125rem;

                .thumbnail {
                    width: $thumbnailDimension;
                    height: $thumbnailDimension;
                    margin-right: 3rem;
                    opacity: 0;
                    visibility: hidden;
                    transform: scale(0);
                    transition: all 0.3s ease-in-out;
                    display: block;
                }

                h4 {
                    width: 40rem;
                    margin-right: 1.875rem;
                    padding: 2rem 0;
                    font-size: 1.75rem;
                    line-height: 2.375rem;
                }

                $linkDimension: 3.6875rem;

                a.btn-arrow {
                    color: $red;
                    width: $linkDimension;
                    height: $linkDimension;
                    border-radius: 50%;
                    background: $white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 0;
                    position: relative;
                    left: -50px;
                    transition: all 0.3s ease-in-out;

                    .fa {
                        font-size: 3.125rem;
                        color: $red;
                        position: relative;
                        top: -2px;
                        left: 2px;
                    }
                }

                &.active {
                    h4 {
                        color: $white;
                    }

                    a.btn-arrow {
                        opacity: 1;
                        left: 0;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1830px) {
    .pillars {
        .listings {
            .active {
                .thumbnail {
                    opacity: 1;
                    visibility: visible;
                    transform: scale(1);
                }
            }
        }
    }
}