﻿word-cloud {
    display: block;
    position: relative;
    font-family: $coveredGrace;
    font-size: 37px;

    span {
        white-space: nowrap;
        padding: .25rem .75rem;
        text-transform: lowercase;

        &.w1, &.w4, &.w7, &.w10 {
            color: $red;
        }

        &.w2, &.w5, &.w8 {
            color: $blue;
        }

        &.w3, &.w6, &.w9 {
            color: $light-grey;
        }
    }
}
