﻿overlay-tile, .overlay-tile {
    &, .container {
        display: flex;
    }

    .container {
        background-size: cover;
        align-items: center;
        min-height: 640px;
        padding: 1rem;
        position: relative;
        overflow: hidden;
        width: 100%;
        z-index: 1;
    }

    .overlay {
        display: block;
        background-image: linear-gradient(to bottom, transparent 0, rgba($blue,.8) 28.571%);
        position: absolute;
        top: 60%;
        left: 0;
        height: 140%;
        width: 100%;
    }

    h4 {
        font-size: 1.25rem;
        margin-bottom: 1.25rem;
    }

    h3 {
        margin-bottom: 0;
    }

    h3, h4, p {
        color: $white;
    }

    .below-fold {
        margin-top: 2rem;
    }

    .button {
        margin-top: 2rem;
    }

    @include breakpoint(medium) {
        .container {
            padding: 3rem;
        }
    }
}

.thumbnail-tile {

    $borderRadius: 30px;
    min-height: 550px;
    background-image: linear-gradient(to bottom, transparent $borderRadius, $light-gray $borderRadius);
    border-radius: $borderRadius;
    display: block;
    overflow: hidden;

    .bg-container {
        background-size: cover;
        height: 375px;
        padding: 0 2.9375rem;
        position: relative;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
        overflow: hidden;

        .overlay {
            display: block;
            background-image: linear-gradient(to bottom, transparent 0, rgba($blue,.8) 16.667%);
            position: absolute;
            top: 55%;
            left: 0;
            height: 140%;
            width: 100%;
            z-index: 1;
        }

        h4 {
            color: $white;
            position: absolute;
            bottom: 30px;
            left: 0;
            width: 100%;
            padding: 0 2.9375rem;
            z-index: 2;
        }
    }

    .text-container {
        padding: 3.125rem 2.9375rem 0;
        overflow: hidden;

        h5 {
            font-family: $museo;
            color: $red;
            font-weight: 700;
        }

        .rte {
            margin-top: 1.25rem;
            padding-bottom: 2.875rem;
            transition: visibility .1s ease-out, opacity .1s ease-out;

            p {
                color: $blue;
            }

            a {
                margin-top: 3.125rem;

                &::after {
                    text-indent: initial;
                }
            }
        }
    }

    &.hoverable {
        box-shadow: none;
        transition: box-shadow .3s;
        overflow: visible;

        .overlay {
            transition: top .3s;
        }

        &:hover {
            box-shadow: 0px 2px 5px 0px rgba($black,0.3);

            .overlay {
                top: -40%;
            }
        }
    }

    @include breakpoint(medium) {
        .bg-container {
            .overlay {
                top: 60%;
            }
        }
    }
}

.simple-tile {
    min-height: 640px;
    padding: 1rem;
    display: flex;
    align-items: center;
    &, h1, h2, h3, h4, h5, h6 {
        color: $white;
    }

    > div {
        width: 100%;
    }

    @include breakpoint(medium) {
        padding: 1rem 3rem;
    }

    @include breakpoint(large) {
        padding: 1rem 5rem;
    }
}