﻿@mixin input-style() {
    color: $blue;
    font-size: 1rem;
    font-family: $overpass;
}

input[type="text"],
input[type="email"],
textarea {
    border: 0;
    border-bottom: 1px solid $light-grey;
    box-shadow: none;
    background-color: transparent;
    padding-left: 0;
    @include input-style();

    &:focus {
        border: 0;
        border-bottom: 1px solid $light-grey;
        box-shadow: none;
    }

    &::-webkit-input-placeholder { /* Edge */
        @include input-style();
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        @include input-style();
    }

    &::placeholder {
        @include input-style();
    }
}

.radiobuttonlist,
.umbraco-forms-form .radiobuttonlist {
    input[type="radio"] {
        display: none !important;
        opacity: 0;
        width: 0 !important;
    }
}

textarea {
    resize: none;
    height: 11.75rem;
}

.umbraco-forms-field {
    margin-bottom: 1.25rem;

    label {
        font-family: $overpass;
        font-size: 1rem;
        margin-bottom: 0.625rem;
    }

    &.singlechoice {
        .umbraco-forms-tooltip {
            font-weight: bold;
            margin-bottom: .625rem;
            display: block;
        }
    }
}

.radiobuttonlist {
    width: 100%;
    margin-top: 10px;

    .cell {
        margin-bottom: .5rem;
    }

    label {
        color: $blue;
        font-weight: normal;
        position: relative;
        display: inline-flex;
        align-items: center;
        margin: 0 10px 0 0;
        min-width: 160px;

        &::before {
            content: "";
            display: inline-block;
            margin: 0 5px 0 0;
            width: 20px;
            height: 20px;
            border-radius: 11px;
            border: 1px solid $green;
            background-color: transparent;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    input:checked + label::after {
        border-radius: 11px;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 50%;
        margin-top: -6px;
        left: 4px;
        content: "";
        display: block;
        background: $green;
    }
}

.umbraco-forms-label {
    font-weight: bold;
}

.radiobuttonlist {
    display: flex;
    flex-flow: column wrap;

    .form-check {
        width: 100%;
    }
}

.umbraco-forms-navigation {
    margin-top: 0.5rem;

    button {
        margin-top: 2rem;
    }

    p {
        a {
            text-decoration: underline;
            color: $white;

            &:hover {
                color: $blue;
            }
        }
    }
    /*.button {
        border: none;
        background: transparent;
        color: $red;
        border: 1px solid $red;
        border-radius: 3.5rem;
        min-width: 10.625rem;
        font-size: 1rem;
        text-align: left;
        padding: 1.25rem 0 1.125rem 1.875rem;
        position: relative;

        &::after {
            font-family: $shift;
            content: '\f009';
            color: $red;
            font-size: 3.125rem;
            line-height: 3.375rem;
            position: absolute;
            top: -1px;
            right: -1px;
            border: 1px solid $red;
            width: 3.562556rem;
            height: 3.5rem;
            border-radius: 3.5rem;
            text-align: center;
            background: $white;
        }
    }*/
}

.umbraco-forms-inline-message {
    legend {
        font-family: $museo;
        font-size: 2.3125rem;
        line-height: 2.875rem;
        color: $white;
        font-weight: bold;
        margin-bottom: 1.625rem;
    }

    input[type="text"] {
        &, &::placeholder {
            font-family: $coveredGrace;
            font-size: 2.5rem;
            line-height: 2.875rem;
        }

        &::placeholder {
            color: grey;
        }

        border-width: 0;
        background: linear-gradient(to top, transparent .125rem, $white .125rem, $white calc(.125rem + 1px), transparent calc(.125rem + 1px));
        padding-bottom: 0.625rem;
    }

    button {
        color: $blue;
        font-family: $museo;
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.375rem;
        cursor: pointer;

        &:active, &:focus {
            outline: none;
        }
    }

    .umbraco-forms-navigation {
        p {
            font-weight: 700;
            font-family: $museo;
            font-size: 1.125rem;
            line-height: 1.5rem;
            margin-bottom: 0;

            .fa {
                margin-right: 0.5rem;
            }
        }
    }
}

.field-validation-error {
    color: $red;
}

.newsletter-form {
    width: 400px;
    max-width: 100%;

    .form-control {
        &, &:focus {
            border: 1px solid $grey;
        }

        font-weight: bold;
        height: 56px;
        border-radius: 28px;
        padding: .75rem 1.875rem;
        font-size: 1rem;
        margin-bottom: 2rem;
    }
}

@include breakpoint(large) {
    .radiobuttonlist {
        flex-flow: row wrap;

        .form-check {
            width: 50%;
        }
    }
}

.landing-page-form {
    span.field-validation-error {
        margin-bottom: 25px !important;
        display: block;
    }

    .umbraco-forms-caption {
        margin-bottom: 25px;
    }

    input.text {
        height: 2.4375rem;
        margin: 0 0 1rem;
        padding: .5rem !important; 
    }

    input.btn.btn-primary {
        border: none;
        background: transparent;
        color: #EE4056 !important;
        border: 1px solid #EE4056;
        border-radius: 3.5rem;
        min-width: 10.625rem;
        font-size: 1rem;
        text-align: left;
        display: inline-block;
        padding: 0.8125rem 4.6875rem 0.8125rem 1.5625rem;
        position: relative;
        cursor: pointer;
        font-weight: bold;
        line-height: 1.75rem;
        margin-top: 25px;
    }

    input.btn:after {
        font-weight: normal;
        font-family: "Shift";
        content: '\f009';
        color: #EE4056;
        font-size: 3.125rem;
        line-height: 3.375rem;
        position: absolute;
        top: -1px;
        right: -1px;
        border: 1px solid #EE4056;
        width: 3.562556rem;
        height: 3.5rem;
        border-radius: 3.5rem;
        text-align: center;
        transition: background-color 0.3s ease-in-out, color .3s ease-in-out;
    }

    label.umbraco-forms-label {
        margin-top: 40px;
    }
}


._wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;

    form.newsletter-form {
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
    }
}


.content-area .grid.grid-level-1 {
    min-height: 15px;
}

body.tool-and-resources-newsletter {
    height: 100vh;

    img.logo {
        position: absolute;
        height: 50px;
        width: 150px;
        left: 50%;
        top: 23%;
        transform: translate(-50%, -15%);
    }
}

#tool-and-resources-newsletter{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .wrapper {
        max-width: 500px;
    }

    .widget {
        display: flex;
        justify-content: center;
    }
}

.sub_newsletter {
    width: 100%;
    padding: 10px;
    max-width: 500px;
    margin: 0 auto;
}
.widget.widget-newsletter.variant-tool-and-resources.layout-basic {
    margin: 0 auto;
    width: 400px;
    margin-top: 100px;
}
@media (max-width:767px) {
    ._wrapper .heading h2 {
        font-size: 24px;
        line-height: normal;
        margin-bottom: 15px;
    }
}

.umbraco_submitted_form {
    padding: 5rem 2rem;
    margin-top: 0;
    background: #fff;
}