﻿.widget-principles {
    &.variant-tiled {
        .grid-x {
            justify-content: center;
        }

        .intro {
            background-image: linear-gradient(to right, $blue, $lighter-blue);
            display: flex;
            padding: 3rem;
            justify-content: center;
            align-items: center;

            h1, h2, h3, h4, h5, h6, p, ol, ul {
                color: $white;
            }
        }

        .rte {
            width: 730px;
            max-width: 100%;
        }

        .container {
            min-height: 250px;
            justify-content: center;
        }

        .above-fold {
            h3 {
                font-size: 1.563rem;
                text-align: center;
            }
        }

        .below-fold {
            margin-top: 1rem;
        }

        .divider {
            height: 1px;
            background-color: $white;
            margin-bottom: 1.5rem;
        }

        @include breakpoint(medium) {
            .grid-x {
                justify-content: start;
            }

            .container {
                min-height: 425px;
                justify-content: start;
            }

            .above-fold {
                h3 {
                    font-size: 1.875rem;
                    text-align: left;
                }
            }
        }

        @include breakpoint(large) {
            .intro, .container {
                padding: 3rem 5rem;
            }
        }
    }
}
