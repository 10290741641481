﻿@import '../_variables.scss';

.layout-icon-links {

    a {
        display: inline-flex;
        align-items: center;
        color: $red;

        .fa {
            margin-right: 10px;
            width: 25px;
        }


        &:hover {
            color: $darker-red;
        }
    }
}

.layout-icon-only-links {
    .icon-links {
        display: flex;
        padding-right: 0;
        margin: 0 -.5rem;
    }

    li {
        margin: 0 .5rem;
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
        border-radius: 100%;
        background: linear-gradient(142deg, $red 0%, $dark-red 100%);
        margin-left: 1.25rem;

        &:first-child {
            margin-left: 0;
        }

        > .fa {
            color: $white;
        }
    }
}