$circleDimension: 252px;
@mixin circleContainer() {
    margin-left: -18px;
    width: $circleDimension;

    &:first-child {
        margin-left: 0;
    }
}

.widget-approach {
    $containerWidth: 1190px;

    .approach-container {
        position: relative;
    }

    .circles {
        display: flex;

        > div {
            display: flex;
            position: relative;
        }

        .circle {
            @include circleContainer();
            opacity: 0.5;

            > div {
                width: $circleDimension;
                height: $circleDimension;
                /*border-radius: 50%;
                border: 18px solid lighten($light-grey, 25);*/
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                p {
                    text-align: center;
                    margin-bottom: 0;
                    padding: 0 3.3rem;
                    color: $blue;
                }
            }
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;
            height: 255px;
            width: 101%;

            path {
                //stroke: $green;
                stroke-width: 18;
                stroke-linecap: round;
                fill: transparent;

                &:nth-child(7) {
                    stroke: url(#linear1);
                }

                &:nth-child(8) {
                    stroke: url(#linear2);
                }

                &:nth-child(9) {
                    stroke: url(#linear3);
                }

                &:nth-child(10) {
                    stroke: url(#linear4);
                }

                &:nth-child(11) {
                    stroke: url(#linear5);
                }
            }

            circle {
                stroke: lighten($light-grey, 25);
                stroke-width: 18;
                stroke-linecap: round;
                fill: transparent;
            }
        }
    }

    .circle-texts {
        display: flex;
        margin-top: 3rem;

        > div {
            display: flex;

            > div {
                @include circleContainer();

                p {
                    width: 100%;
                    padding: 0 1.5rem;
                    font-size: 1rem;
                    text-align: center;
                    color: $blue;
                }
            }
        }
    }

    .carousel-buttons {
        position: absolute;
        top: 20%;

        a {
            $controlDistance: 0;

            &.prev {
                left: $controlDistance;
            }

            &.next {
                right: $controlDistance;
            }

            &.disabled {
                opacity: 0.3;
            }
        }
    }

    @media only screen and (min-width: 75.9375rem) {
        //@include breakpoint(large) {
        .circles {
            justify-content: center;

            .circle {
                opacity: 0.5;
            }

            svg {
                path {
                    &:nth-child(7) {
                        stroke: url(#linear6);
                    }

                    &:nth-child(8) {
                        stroke: url(#linear7);
                    }

                    &:nth-child(9) {
                        stroke: url(#linear8);
                    }

                    &:nth-child(10) {
                        stroke: url(#linear9);
                    }

                    &:nth-child(11) {
                        stroke: url(#linear10);
                    }
                }
            }
        }

        .circle-texts {
            flex-flow: column nowrap;
            align-items: center;

            > div {
                width: 50%;
                display: block;

                > div {
                    margin-left: 0;
                    width: 100%;
                    position: relative;
                    padding-top: 5rem;

                    p {
                        font-size: 1.125rem;
                        line-height: 1.75rem;
                    }

                    $dotDimension: 40px;

                    &::before {
                        content: '';
                        border-radius: $dotDimension;
                        width: $dotDimension;
                        height: $dotDimension;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                    }

                    &:first-child {
                        &::before {
                            background: $red;
                        }
                    }

                    &:nth-child(2) {
                        &::before {
                            background: $green;
                        }
                    }

                    &:nth-child(3) {
                        &::before {
                            background: $orange;
                        }
                    }

                    &:nth-child(4) {
                        &::before {
                            background: $darker-red;
                        }
                    }

                    &:nth-child(5) {
                        &::before {
                            background: #2A5E84;
                        }
                    }
                }
            }
        }

        .controls {
            display: none;
        }
    }
}