.layout-partners {
    position: relative;

    .partners {
        margin-bottom: -2.0625rem;

        .border-right-1 {
            border-width: 0;
        }

        .partners-list {
            padding-top: 3rem;

            .cell {
                margin-bottom: 2.0625rem;
                text-align: center;
            }
        }
    }

    @include breakpoint(large) {
        margin-top: 0;

        .grid-container {
            max-width: 100%;
        }

        .partners {
            margin-bottom: -4.0625rem;

            .border-right-1 {
                border-width: 1px;
            }

            > div.grid-container {
                > div {
                    > .cell {
                        &:first-child {
                            padding-right: 1rem;
                        }
                    }
                }
            }

            > .cell {
                padding-left: 6rem;

                &:first-child {
                    padding: 0 3rem 0 0;
                }
            }

            .partners-list {
                padding-top: 0;

                .cell {
                    margin-bottom: 4.0625rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
    /*@media only screen and (min-width: 87.5rem) {
        .grid-container {
            padding: 0 6rem;
        }
    }


    @media only screen and (min-width: 100rem) {
        .grid-container {
            max-width: 90rem;
            padding: 0;
        }

        .partners {
            > div.grid-container {
                > div {
                    > .cell {
                        &:first-child {
                            padding-right: 5rem;
                        }
        
                        &:nth-child(2) {
                            padding-left: 5rem;
                        }
                    }
                }
            }

        }
    }*/
}
