﻿@mixin font-styling() {
    font-family: $museo;
    color: $white;
    font-weight: 700;
    font-size: 1rem;
    letter-spacing: 0.41px;
    line-height: 1.1875rem;
}

@mixin footerLinkHover {
    &:hover {
        color: $green;
    }
}

.footer {
    background: $blue;
    background: linear-gradient(90deg, rgba(12,39,62,1) 8%, rgba(22,71,112,1) 70%);
    color: $white;
    padding: 2.875rem 0 1.875rem;


    .footer-top {
        display: flex;
        justify-content: space-between;

        .logo {
            width: 7.35rem;
            height: 3.10625rem;

            img {
                width: 100%;
            }
        }

        .get-in-touch {
            display: flex;
            flex-flow: column nowrap;

            p {
                @include font-styling();

                a {
                    color: $white;

                    @include footerLinkHover();
                }
            }

            .social-media-container {
                a {
                    background: transparent;
                    border: 1px solid $white;
                    transition: background-color .3s, border-color .3s;

                    &:hover {
                        background: $green;
                        border-color: $green;
                    }
                }
            }
        }
    }

    .footer-content {
        border-top: 1px solid $light-grey;
        border-bottom: 1px solid $light-grey;
        padding: 1.5rem 0;
        margin-top: 1.875rem;
        display: flex;
        flex-flow: column nowrap;
        margin-bottom: 2.1875rem;

        .footer-links {
            display: flex;
            flex-flow: column nowrap;
            padding-left: 20%;

            > div {
                margin-bottom: 1.875rem;
            }

            h3 {
                @include font-styling();
                margin-bottom: 1.25rem;

                a {
                    @include font-styling();
                    @include footerLinkHover();
                }
            }

            ul {
                list-style: none;
                margin-left: 0;
                margin-bottom: 0;

                li {
                    margin-top: 0.3125rem;

                    &:first-child {
                        margin-top: 0;
                    }

                    a {
                        font-family: $museo;
                        color: $white;
                        font-weight: 500;
                        font-size: 0.875rem;
                        letter-spacing: 0.36px;
                        line-height: 1.0625rem;

                        @include footerLinkHover();
                    }
                }
            }
        }

        .footer-2nd-col {
            text-align: center;
            display: flex;
            flex-flow: column;
            justify-content: space-between;
        }

        .employee-portal {
            @include font-styling();
            position: relative;
            padding-left: 3rem;
            top: -3px;

            &::before {
                font-family: $shift;
                content: '\f008';
                font-weight: 400;
                font-size: 1.875rem;
                position: absolute;
                top: 0;
                left: 0;
                color: $light-grey;
            }

            &:hover {
                color: $green;

                &::before {
                    color: $green;
                }
            }
        }

        .footer-address {
            font-family: $overpass;
            font-size: 0.875rem;
            line-height: 1.25rem;
            text-align: center;
            margin-bottom: 0;

            br {
                display: none;
            }
        }
    }

    .footer-bottom {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;

        p, a {
            font-family: $overpass;
            font-size: 0.625rem;
            line-height: 1.625rem;
            color: $white;
            margin-bottom: 0;
        }

        a {
            @include footerLinkHover();
        }

        .footer-links {
            display: flex;
            flex-flow: column nowrap;

            ul {
                list-style: none;
                margin-left: 0;
                margin-bottom: 0;

                li {
                    display: inline-block;
                    margin-left: 1.25rem;
                    position: relative;

                    &::before {
                        content: '';
                        width: 1px;
                        height: 35%;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: -0.75rem;
                        background: $white;
                    }

                    &:first-child {
                        margin-left: 0;

                        &::before {
                            content: none;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(medium) {
        .footer-content {
            .footer-links {
                justify-content: space-around;
                padding-left: 0;
                flex-flow: row wrap;
            }
        }

        .footer-bottom {
            .footer-links {
                flex-flow: row wrap;
                justify-content: space-around;
                align-items: flex-end;
                width: 100%;
            }
        }
    }

    @include breakpoint(large) {
        padding: 5.8125rem 0 3.75rem;

        .grid-container {
            max-width: 90rem;
        }

        .footer-top {
            .logo {
                width: 10.5rem;
                height: 4.4375rem;
                display: inline-block;

                img {
                    width: 100%;
                }
            }

            .get-in-touch {
                flex-flow: row nowrap;
                align-items: center;
                position: relative;

                p {
                    margin-bottom: 0;
                    margin-right: 4rem;
                    position: relative;

                    &::after {
                        content: '';
                        width: 1px;
                        height: 200%;
                        background: $light-grey;
                        position: absolute;
                        top: 50%;
                        right: -30%;
                        transform: translateY(-50%);
                    }
                }

                .social-media-container {
                    margin-top: 0;
                }
            }
        }

        .footer-content {
            padding: 3.125rem 0 1.875rem;
            justify-content: space-between;
            margin-bottom: 4.375rem;
            flex-flow: row nowrap;

            .footer-links {
                flex-flow: row nowrap;
                align-items: inherit;

                > div {
                    margin-left: 5rem;
                    margin-bottom: 0;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }

            .footer-2nd-col {
                text-align: left;
            }

            .footer-address {
                width: 185px;
                text-align: right;

                br {
                    display: block;
                }
            }
        }

        .footer-bottom {
            justify-content: space-between;
            flex-flow: row nowrap;
            align-items: inherit;

            .footer-links {
                justify-content: space-between;
                flex-flow: row nowrap;
                width: auto;

                ul {
                    margin-right: 1.25rem;
                }

                p {
                    top: 1px;
                    position: relative;
                }
            }
        }
    }
}
