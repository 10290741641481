﻿.widget-cards {
    margin-bottom: -3rem;

    .cell {
        margin-bottom: 3rem;
    }

    &.variant-circle {
        .grid-x {
            justify-content: center;
        }

        .image-container {
            border-radius: 50%;
            width: 21.875rem;
            max-width: 100%;
            margin: 0 auto 3rem;
            border: 12px solid $white;
            box-shadow: 0px 0px 8px 0px rgba($black, .8);

            &::after {
                content: '';
                background: rgba($blue, .6);
                position: absolute;
                top: 50%;
                left: 50%;
                border-radius: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
            }

            div {
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 3;
                padding: 1rem 2rem;
            }
        }

        h4 {
            margin-bottom: 0;
            color: $white;
            text-align: center;
            font-weight: 600;
        }

        .rte {
            text-align: center;
        }
    }
}
