.widget-services {
    &.variant-basic {
        .cell {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            margin-top: 3rem;
        }

        img {
            width: 100px;
            margin-bottom: 1.6875rem;
        }

        p {
            font-family: $museo;
            font-size: 1.125rem;
            line-height: 1.375rem;
            letter-spacing: 0.46px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 0;
        }
    }

    @include breakpoint(large) {
        &.variant-basic {
            .cell {
                margin-top: 0;
            }
        }
    }
}