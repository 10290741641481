@mixin backgroundStyling {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.primary-carousel {
    .slide-off {
        padding-left: 1rem;
        padding-right: 1rem;

        > div {
            cursor: default !important;
        }

        @media only screen and (min-width: 75em) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.widget-carousel {
    .controls {
        $dimensionControl: 3.625rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        width: 100%;

        a {
            display: flex;
            width: $dimensionControl;
            height: $dimensionControl;
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
            border-radius: $dimensionControl;
            justify-content: center;
            align-items: center;
            background: $white;
            position: absolute;
            opacity: 1;
            transition: opacity .3s;

            &.disabled {
                opacity: .5;
                pointer-events: none;
            }

            .fa {
                color: $red;
                font-size: 40px;

                @media(max-width:767px) {
                    &.fa-angle-right {
                        margin-left: 4px;
                    }

                    &.fa.fa-angle-left {
                        margin-right: 4px;
                    }
                }
            }

            $controlDistance: 0.75rem;

            &.prev {
                left: $controlDistance;
            }

            &.next {
                right: $controlDistance;
            }
        }
    }

    .intro {
        margin-bottom: 2rem;

        img {
            margin-bottom: 1rem;
        }
    }

    @include breakpoint(medium) {
        .controls {
            a {
                $controlDistance: 3.75rem;

                &.prev {
                    left: $controlDistance;
                }

                &.next {
                    right: $controlDistance;
                }
            }
        }


        .intro {
            margin-bottom: 5rem;
        }
    }



    &.layout-dark {
        background-image: linear-gradient(to right, $blue, $lighter-blue);
        padding: 3rem 0;

        .intro {
            h1, h2, h3, h4, h5, h6, p, ol, ul {
                color: $white;
            }
        }

        @include breakpoint(medium) {
            padding: 6rem;
        }
    }

    &.layout-full-screen {
        position: relative;

        > div {
            min-height: 100vh;
        }

        .banner-bg {
            @include backgroundStyling();
            z-index: 0;

            > .backgrounds {
                @include backgroundStyling();
                visibility: hidden;

                &::before {
                    content: '';
                    background: rgba(12, 39, 62, 0.8);
                    bottom: 0;
                    left: 0;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 20;
                }

                > div {
                    @include backgroundStyling();
                    background-size: cover;
                    background-position: top center;
                    opacity: 0;
                    z-index: 1;

                    &.active {
                        opacity: 1;
                        z-index: 2;
                    }
                }
            }
        }

        .overview-navs {
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            position: relative;
            z-index: 2;
            color: $white;
            padding-top: 3rem;

            h2 {
                color: $blue;
                margin-bottom: 1rem;
            }

            ul {
                list-style: none;
                margin-left: 0;
                margin-top: 2rem;

                li {
                    display: inline-block;
                    margin-right: 0.875rem;
                    margin-bottom: 1rem;

                    a {
                        display: block;
                        padding: 0.5rem 1.25rem 0.3125rem;
                        color: $white;
                        font-size: 1rem;
                        line-height: 1.5625rem;
                        border: 1px solid $white;
                        border-radius: 20px;

                        &:hover {
                            background: $white;
                            color: $blue;
                        }
                    }

                    &.active {
                        a {
                            background: $white;
                            color: $blue;
                        }
                    }
                }
            }
        }

        .contents {
            z-index: 2;
            position: relative;
            padding: 0;
            margin-top: 3rem;

            > div {
                margin-bottom: 3rem;

                .separator {
                    padding-top: 2rem;
                    margin-bottom: 1.5rem;
                    position: relative;

                    &::after {
                        content: '';
                        background: $offwhite;
                        width: 100%;
                        height: 1px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                }

                h2 {
                    margin-top: 2.5rem;
                }

                h2, h4 {
                    color: $green;
                }

                h2 {
                    line-height: 1rem;
                }

                p {
                    color: $blue;
                }

                .button {
                    margin-top: 1.75rem;
                    height: 56px;
                    position: relative;
                }

                a.with-right-arrow {
                    color: $blue;
                    font-family: $museo;
                    letter-spacing: 0.5px;
                    font-weight: 700;
                    margin-top: 0.625rem;

                    &::after {
                        color: $green;
                        font-weight: normal;
                    }
                }

                &:first-child {
                    opacity: 1;
                    display: block;
                }
            }

            .featured-project-info {
                h4 {
                    font-weight: 600;
                }
            }
        }

        .controls {
            $dimensionControl: 3.625rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            width: 100%;

            a {
                display: flex;
                width: $dimensionControl;
                height: $dimensionControl;
                box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
                border-radius: $dimensionControl;
                justify-content: center;
                align-items: center;
                background: $white;
                position: absolute;

                .fa {
                    color: $red;
                    font-size: 40px;
                }

                $controlDistance: 0.75rem;

                &.prev {
                    left: $controlDistance;
                }

                &.next {
                    right: $controlDistance;
                }

                &.disabled {
                    opacity: 0.3;
                    cursor: default;
                }
            }
        }

        @include breakpoint(medium) {
            .banner-bg {
                > .backgrounds {
                    visibility: visible;
                }
            }

            .overview-navs {
                padding-top: 8.4375rem;

                h2 {
                    color: $white;
                    margin-bottom: 2.5rem;
                }
            }

            .contents {
                padding: 0 4.5rem 8.4375rem;
                margin-top: 6.5rem;

                > div {
                    opacity: 0;
                    display: none;
                    margin-bottom: 0;

                    .image-container {
                        visibility: hidden;
                        position: absolute;
                    }

                    .separator {
                        padding-top: 4.375rem;
                        margin-bottom: 2.5rem;
                    }

                    h2 {
                        margin-top: 0;
                    }

                    p {
                        color: $white;
                    }

                    .button {
                        margin-top: 3.75rem;
                    }

                    a.with-right-arrow {
                        color: $white;
                    }
                }
            }
        }

        @include breakpoint(large) {
            .banner-bg {
                > .backgrounds {
                    &::before {
                        background: linear-gradient(95deg, #0c273e 40%, rgba(255,255,255,0) 75%);
                    }
                }
            }

            .contents {
                padding: 0 0 8.4375rem;
            }

            .controls {
                a {
                    $controlDistance: 0.75rem;

                    &.prev {
                        left: $controlDistance;
                    }

                    &.next {
                        right: $controlDistance;
                    }
                }
            }
        }

        @media only screen and (min-width: 112.5rem) {
            .controls {
                $controlDistance: 3.75rem;

                a {
                    &.prev {
                        left: $controlDistance;
                    }

                    &.next {
                        right: $controlDistance;
                    }
                }
            }
        }
    }

    &.variant-industries {

        .slanted-background {
            fill: linear-gradient(90deg, rgba(12,39,62,1) 10%, rgba(22,71,112,1) 80%);
        }

        &:not(.layout-full-screen) {
            .controls {
                .prev {
                    display: none;
                }
            }
        }

        .source-industries {

            &.grid-carousel {
                position: relative;
                width: 100%;

                .content, .carousel-container {
                    width: 100%;
                }
            }

            position: relative;

            .intro {
                text-align: right;
                position: relative;
                z-index: 1;
            }

            .intro, .follow-up {
                h1, h2, h3, h4, h5, h6, p, ol, ul {
                    color: $white;
                }
            }

            .follow-up {
                h2 {
                    margin-bottom: .5rem;
                }

                p {
                    font-size: 1.25rem;
                }
            }

            $paddingRight: 20%;

            .primary-carousel {
                height: 40.625rem;

                .slide {

                    .container {
                        position: relative;
                        height: 650px;

                        &.additional-content {
                            background-color: $green;
                            display: flex;
                            justify-content: center;
                            align-items: flex-end;
                            padding: 6rem 3rem 2rem;

                            .rte {
                                text-align: left;
                            }
                        }

                        &:not(.additional-content)::after {
                            content: '';
                            position: absolute;
                            background-image: linear-gradient(0deg, rgba(12,39,62,1) 0, rgba(22,71,112,0) 65%);
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .bg-container {
                        height: 100%;
                        background-repeat: no-repeat;
                        background-size: cover;
                        padding: 9rem 5rem 8.5rem 3rem;
                        display: flex;
                        align-items: flex-end;
                        justify-content: center;
                    }
                }

                compile {
                    flex: 0 1 400px;
                    max-width: 400px;
                }

                .slide-text {
                    width: 387px;
                    max-width: 100%;
                    position: relative;
                    z-index: 2;
                    color: $white;

                    h2 {
                        color: $white;
                        font-size: 2.3125rem;
                        margin-bottom: .75rem;
                        font-weight: 700;
                    }

                    h5 {
                        color: $white;

                        &::after {
                            height: 2px;
                            background: $light-grey;
                            bottom: 8px;
                        }
                    }
                }

                .umbraco-forms-inline-message {
                    margin-top: 1rem;
                }
            }

            .rte {
                > *:last-child {
                    margin: 0 auto;
                }
            }
        }

        .small-image-container-mobile-banner {
            height: 250px;
        }

        @include breakpoint(large) {
            .source-industries {
                .primary-carousel {
                    height: 46.875rem;

                    .slide {
                        .container {
                            height: 750px;

                            &.additional-content {
                                padding: 6rem 3rem 3rem;
                            }
                        }

                        .bg-container {
                            padding: 9rem 9rem 8.5rem;
                        }
                    }

                    .umbraco-forms-inline-message {
                        margin-top: 4.6875rem;
                    }
                }
            }
        }
    }

    &.variant-case-studies, &.variant-services {
        @include breakpoint(medium) {
            .grid-container {
                max-width: $grid-container * 5 / 6;
            }

            .slides {
                max-width: #{75rem - $mediumGutters * 2};
                margin: 0 auto;
                overflow: visible;
            }
        }
    }

    &.variant-case-studies {
        .bg-container {
            background-position: top center;
        }

        @include breakpoint(large) {
            .bg-container {
                background-position: initial;
            }
        }
    }

    &.variant-services {
        .thumbnail-tile {
            min-height: 625px;

            h4 {
                font-weight: bold;
            }

            p {
                color: $blue;
            }
        }
    }

    .variant-stories {
        position: relative;
        margin-top: 4.6875rem;

        $borderRadius: 30px;
        $paddingRight: 10%;

        .slides {
            //padding-left: $paddingRight;
            //padding-right: $paddingRight;
        }

        .slide {
            border-radius: inherit;

            .container {
                height: 550px;
                background: $light-gray;
                border-radius: $borderRadius;
                margin-left: 1.5625rem;
                margin-right: 1.5625rem;

                .bg-container {
                    background-size: cover;
                    height: 375px;
                    border-top-right-radius: $borderRadius;
                    border-top-left-radius: $borderRadius;
                    padding: 0 2.9375rem;
                    position: relative;

                    &::after {
                        content: '';
                        background: linear-gradient(50deg, rgba(12,39,62,1) 11%, rgba(22,71,112,0) 45%);
                        bottom: 0;
                        left: 0;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        border-top-right-radius: $borderRadius;
                        border-top-left-radius: $borderRadius;
                    }

                    h4 {
                        color: $white;
                        position: absolute;
                        bottom: 30px;
                        left: 0;
                        width: 100%;
                        padding: 0 2.9375rem;
                        z-index: 2;
                    }
                }

                .text-container {
                    padding: 3.125rem 2.9375rem 0;

                    p {
                        font-family: $museo;
                        color: $red;
                        font-weight: 700;
                    }
                }
            }
        }
        /*.controls-carousel {
            $dimensionControl: 3.625rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            width: 100%;

            a {
                display: flex;
                width: $dimensionControl;
                height: $dimensionControl;
                box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
                border-radius: $dimensionControl;
                justify-content: center;
                align-items: center;
                background: $white;
                position: absolute;

                .fa {
                    color: $red;
                    font-size: 40px;
                }

                $controlDistance: 3.75rem;

                &.prev {
                    left: $controlDistance;
                }

                &.next {
                    right: $controlDistance;
                }kas
            }
        }*/
    }

    &.variant-brands {
        .image-container {
            padding-top: 100%;

            &.image-logo {
                width: 200px;
            }
        }
    }
}



.caseStudies_slider {
    .thumbnail-tile .bg-container .overlay {
        top: -100px;
        opacity: 0.4;
    }

    @media (min-width:1200px) {
        .controls {
            a.prev {
                left: 1rem;
            }

            a.next {
                right: 1rem;
            }
        }

        .thumbnail-tile .bg-container h4 {
            padding: 0 5rem;
        }
    }

    @media (max-width:1199px) {
        .carousel-container {
            padding: 0 15px;
        }

        .controls a {
            top: 75px;

            a.next {
                right: 1.75rem;
            }

            a.prev {
                left: 1.75rem;
            }
        }
    }

    @media (max-width:991px) {
        .thumbnail-tile {
            .bg-container h4 {
                font-size: 1.75rem;
                line-height: 2rem;
                padding: 0 1.5rem;
            }

            .text-container {
                padding: 3.125rem 1.5rem 0;
            }
        }
    }

    @media (max-width:767px) {
        .thumbnail-tile .bg-container h4 {
            font-size: 1.75rem;
            line-height: 2rem;
        }

        .controls a {
            width: 3rem;
            height: 3rem;
        }
    }
}

.services_slider {

    .slide {
        vertical-align: top;
    }

    @media (min-width:1200px) {
        .thumbnail-tile .bg-container h4 {
            padding: 0 5rem;
        }

        .controls {
            a.prev {
                left: 1rem;
                top: -10px;
            }

            a.next {
                right: 1rem;
                top: -10px;
            }
        }
    }

    @media (max-width:1199px) {

        .controls {
            top: 346px;
            transform: none;

            a.next {
                right: 1.75rem;
            }

            a.prev {
                left: 1.75rem;
            }
        }
    }

    @media (max-width:991px) {
        .thumbnail-tile {
            .bg-container h4 {
                padding: 0 1rem;
            }

            .text-container {
                padding: 2rem 1.2rem 1rem;
            }

            p {
                color: #0C273E;
                line-height: 1.6rem;
            }
        }
    }

    @media (max-width:767px) {
        .carousel-container {
            padding: 0 15px;
        }
    }
}


.industry-slider {
    @media(max-width:1199px) {
        rotating-text .rotating-box-container .box .text-container h5 {
            min-height: 160px;
        }

        .bg-container.loaded {
            padding: 20px 36px !important;
        }
    }
}
