﻿link-video-modal {
    .full-video-modal-background {
        padding: 1rem;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .full-video-modal-wrapper {
        width: 75rem;
        max-width: 100%;
        max-height: 100%;
        cursor: move;
        position: relative;
        text-align: right;
        overflow-y: auto;
        z-index: 2;
        margin: 0 auto;

        .close {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: $white;
            color: $red;
            position: relative;
            transition: .3s background-color;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 1rem;
            right: 0;
            top: 0;
            font-size: 2em;

            &:hover {
                background-color: $red;
                color: $white;
            }
        }
    }
}
