﻿/*----COLORS----*/
$white:     #fff;
$offwhite:   #FFF1E9;
$black:     #000;
$dark-green: #19A79A;
$green:     #4DC0B6;
$light-green:   #6DBDBE;
$light-blue: #4d99c0;
$lighter-blue: #15446b;
$blue:      #0C273E;
$red:       #EE4056;
$dark-red:  #BF0057;
$darker-red: #822F43;
$light-grey: #A6ACB4;
$grey:      #3F454F;
$orange:    #F97253;

/*----FONTS----*/
$museo: museo-slab, serif;
$overpass: 'Overpass', sans-serif;
$coveredGrace: 'Covered By Your Grace', cursive;
$shift: 'Shift';

/*----ANIMATION----*/
$defaultAnimationSpeed: .3s;

/*----GRID----*/
$grid-container: 90rem;
$grid-margin-gutters: ( small: 20px, medium: 32px );
$smallGutters: rem-calc(map-get($grid-margin-gutters, small) / 2);
$mediumGutters: rem-calc(map-get($grid-margin-gutters, medium) / 2);
$headerInitialHeight: 136px;
$headerScrolledHeight: 111px;