﻿.widget-page-data {
    &.variant-author-date {
        font-family: $museo;
        font-size: 1rem;
        font-weight: bold;
        .date {
            color: $light-grey;
        }
        .author {
            color: $blue;
            margin-bottom: 0;
        }
    }
}